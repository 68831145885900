/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
export interface AuthReducer {
  isLoading: boolean
}

const initialState = {
  isLoading: false
}

export interface SignInPayload {
  email: string
  password: string
}

export type SignInAction = PayloadAction<SignInPayload>

export const SIGN_IN = createAction<SignInPayload>('SIGN_IN')
export const SIGN_IN_SUCCESS = createAction('SIGN_IN_SUCCESS')
export const SIGN_IN_FAIL = createAction('SIGN_IN_FAIL')

export const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(SIGN_IN, (state, action) => {
    return { ...state, isLoading: true }
  })
})
export default authReducer
