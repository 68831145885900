import { combineReducers, Reducer } from 'redux'
import authReducer, { AuthReducer } from './authReducer'
export interface RootReducer {
  authReducer: AuthReducer
}
const rootReducer: Reducer<RootReducer> = combineReducers({
  authReducer
})

export default rootReducer
