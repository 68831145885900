import React from 'react'
import './App.css'
import store from './store/store'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SignIn from './components/organisms/SignIn'

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <>
          <Routes>
            <Route path="/users/sign_in" Component={SignIn} />
          </Routes>
        </>
      </BrowserRouter>
    </Provider>
  )
}

export default App
